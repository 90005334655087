var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-widget',{attrs:{"title":"Activos Asignados","badge":_vm.totalCount,"simpleToolbar":true,"icon":"shopping_cart","toolbarColor":"secondary"}},[(_vm.allowEdit)?_c('div',{attrs:{"slot":"widget-header-action"},slot:"widget-header-action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"shortkey",rawName:"v-shortkey",value:(['insert']),expression:"['insert']"}],attrs:{"icon":""},on:{"click":_vm.createAsset,"shortkey":_vm.createAsset}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,2490152356)},[_c('span',[_vm._v(_vm._s(_vm.L('Agregar Activo Fijo')))])])],1):_vm._e(),_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({up: ['ctrl','arrowup'], down: ['ctrl','arrowdown'], plus: ['ctrl','+'], minus: ['ctrl','-'], delete: ['del']}),expression:"{up: ['ctrl','arrowup'], down: ['ctrl','arrowdown'], plus: ['ctrl','+'], minus: ['ctrl','-'], delete: ['del']}"}],on:{"shortkey":_vm.onRowSelection}},[_c('v-data-table',{staticClass:"elevation-0 striped products-details",attrs:{"headers":_vm.columns,"items":_vm.list,"options":_vm.options,"loading":_vm.loading,"loading-text":"Cargando... Por favor Espere","dense":"","disable-pagination":_vm.totalCount < 11,"hide-default-footer":_vm.totalCount < 11,"footer-props":{
                              itemsPerPageOptions: [5,10,20,50],
                              itemsPerPageText: 'Registros por Pagina',
                              showFirstLastPage: true,
                            }},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onSelecteItems},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                            var item = ref.item;
return [_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteAsset(item)}}},[_vm._v(" delete ")])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['alt', 'p']),expression:"['alt', 'p']"}],staticStyle:{"height":"100px","width":"100px"},attrs:{"icon":"","large":""},on:{"click":_vm.createAsset,"shortkey":_vm.createAsset}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("add_box")])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Agrege sus activos!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('add-asset',{attrs:{"oentity":_vm.entity},on:{"add-asset":_vm.afterAddAsset},model:{value:(_vm.createModalShow),callback:function ($$v) {_vm.createModalShow=$$v},expression:"createModalShow"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }